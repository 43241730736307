//
// Form editors.scss
//

// ck-editor

.ck-editor__editable {
  min-height: 250px !important;
}

.ck{
  &.ck-reset_all, &.ck-reset_all *{
    color: $gray-700 !important;
  }

  &.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border-color: $input-border-color !important;
  }

  &.ck-toolbar{
    background-color: $gray-300 !important;
    border-color: $input-border-color !important;

    .ck.ck-toolbar__separator{
      background-color: $input-border-color !important;
    }
  }

  &.ck-tooltip .ck-tooltip__text{
    background-color: $dark !important;
    color: $light !important;
  }

  &.ck-editor__main>.ck-editor__editable{
    background-color: $card-bg !important;
  }
  
}

.ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover{
  background-color: darken($gray-300, 2%) !important;
}


// Summernote

.note-editor{
    &.note-frame {
      border: 1px solid $input-border-color;
      box-shadow: none;
      margin: 0;
  
      .note-statusbar {
        background-color: $gray-300;
        border-top: 1px solid $gray-300;
      }

      .note-editing-area{
        .note-editable, .note-codable {
          border: none;
          color: $gray-500;
          background-color: transparent;
        }
      }
  
    }
  }

  .note-btn-group{
    .note-btn{
      background-color: $gray-300 !important;
      border-color: $gray-300 !important;
    }
  }
  
  .note-status-output {
    display: none;
  }
  
  .note-editable {
  
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  
  .note-popover .popover-content .note-color .dropdown-menu,
  .card-header.note-toolbar .note-color .dropdown-menu {
      min-width: 344px;
  }
  
  .note-popover{
    border-color: $gray-300;
  }
  
  .note-popover .popover-content, 
  .card-header.note-toolbar{
    background-color: $gray-300;
  }

  .note-toolbar {
    padding: 0 0 5px 5px !important;
}
